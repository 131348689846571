import Swiper from 'swiper';
import 'swiper/css';
import './styles.scss';

document.addEventListener('DOMContentLoaded', function () {
  document.querySelectorAll('[data-swiper]').forEach((swiperElement) => {
    const swiper = new Swiper(swiperElement, {
      slidesPerView: 'auto',
      watchOverflow: true,
      freeMode: true,
      freeModeMomentumRatio: 0.8,
      freeModeMomentumVelocityRatio: 0.8,
      breakpoints: {
        1024: {
          noSwipingClass: 'noClass',
        },
      },
    });

    const slideToIndex = Array.from(swiperElement.querySelectorAll('.swiper-wrapper .swiper-slide')).indexOf(
      swiperElement.querySelector('.swiper-slide-active')
    );

    if (slideToIndex > -1) {
      swiper.slideTo(Math.min(slideToIndex, 0), 200);
    }
  });
});
